// gatsby-browser.js

import '@picocss/pico';

export const onInitialClientRender = () => {
    const script = document.createElement('script');
    script.src = 'https://identity.netlify.com/v1/netlify-identity-widget.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
        //console.log('Netlify Identity Widget script loaded');
        if (window.netlifyIdentity) {
            window.netlifyIdentity.init({
                locale: 'pl' // Set the widget's locale to Polish
            });
            //console.log('Netlify Identity Widget initialized with Polish locale');
        }
    };
};
